import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

export const SignatureRequestPageTemplate = () => (
  <main role="main" className="privacy-page">
    <Helmet>
      <title>Signature Request</title>
      <script src="https://cdn.hellosign.com/public/js/embedded/v2.11.1/embedded.production.min.js"></script>
    </Helmet>
    <div className="about-main">
      <iframe id="form-sig" height="100%" width="100%"></iframe>
    </div>
  </main>
);

const SignatureRequestPage = ({ location }) => {
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const case_id = params.get("case_id");
    const form_type = params.get("form_type");

    fetch("https://api.stage.petehealth.com/webhooks/signature/request", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        case_id,
        form_type,
        embed: true
      }),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      const client = new HelloSign();
      client.open(data.sign_url, {
        skipDomainVerification: true,
        container: document.getElementById('form-sig'),
        clientId: 'e2064e21d1f358274ddab78cb451ac2e' // Staging
        // clientId: 'cfac6a76152559fa644845ac29d02280' // Production
      });
    })
    .catch((error) => {
      console.error("Error:", error);
      alert(error);
    });
  }, []);

  return (
    <Layout>
      <SignatureRequestPageTemplate />
    </Layout>
  );
};

export default SignatureRequestPage;
